import React from 'react';

import Layout from '../components/Layout';

import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';

import pic2 from '../assets/images/pic02.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <div id="main">
      <header className="major container medium">
        <h2>We visited a Car Dealer</h2>        
      </header>

      <div className="box container">
        <section>
          <p>            
              <img src={pic2} alt="" width="100%"/>            
          </p>
          <blockquote>
            Buying a car from Fast Track and dealing with the owner Sheila is fun, but we didn’t buy 
            the car. Price started at 320.000 peso for an old 2000 Toyota Hilux 4×4 Special Edition, 
            and we managed to secure a 22% discount on the price, but decided to let the deal pass and 
            find something newer. The car ran great but had some minor defects needing repair before 
            it would suit our purpose. Fun to ride, when down on Panay every 3-4 month and able to go 
            the extra mile (staying in piece) on stone and gravel roads, where you would never get your 
            Ferrari racing across the first end of paved road and onto the gravel. Just to bumpy but 
            fun if you are in a 4×4.
          </blockquote>
          <blockquote>
            On not really knowing what we wanted on our search for a great car we started on looking at 
            almost new Ford Focus 1.8 Ghia from 2006 and only 33.000Km on the odometer, set at 550.000 Peso 
            “lang”, the Philippine word for only. A lot a car at great price compared to and old Toyota 
            Corolla 1,3 heap from 1991, no km record, layers of paint we not able to count, but a price of 
            160.000 peso.
          </blockquote>
          <blockquote>
            We also saw a Honda CRV 2007 at 950.000 peso, and the best bargin a Nissan Teranno 2001, 153.000km 
            at 320.000 peso and it really ran perfectly and no faults at all, but we where to slow, sold before 
            we could return our bid. If you want to buy a car in the Philippines 30% down and then maybe you can 
            get the rest in the bank, but cash is best here. But be sure to bargin on the price, big discounts 
            on having cash, is a sure thing.
          </blockquote>
        </section>
        <section>
          <header>
            <h3>Blog list</h3>
          </header>
          <ul className="default">
            <li>
              <Link to="/we-visited-a-vulcanizing-shop-in-panay">WE VISITED A VULCANIZING SHOP IN PANAY</Link>
            </li>
            <li>
              <Link to="/we-visited-a-car-dealer">WE VISITED A CAR DEALER</Link>
            </li>
            <li>
              <Link to="/we-visited-a-workshop">WE VISITED A WORKSHOP</Link>
            </li>           
          </ul>
          <p><Link to="/">Back to Home Page</Link></p>
        </section>
      </div>

      <footer className="major container medium">
        <h3>Contact Us for more information</h3>
        <p>
          Car collectors choice aim to share the knowledge and experience in an online world. We offer design and build services for you to have an idea.
          Feel free to contact us and we will get back to you as soon as it possible.
        </p>
        <ul className="actions special">
          <li>
            <a href="mailto:support@sal-tech.com?Subject=Carcollectorschoice%20Inquiry" className="button" target="_top">
              Contact Us
            </a>
          </li>
        </ul>
      </footer>
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
